<template>
  <div>
    <el-dialog
      title="费用明细"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="40%"
      :close-on-click-modal="false"
    >
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="费用类别" prop="expenseTypeId">
              <type-cascader
                v-model="form.expenseTypeId"
                info="ExpenseType"
                :canAdd="true"
                auth="ExpenseType"
                @addClick="()=>{this.expenseTypeItem = {}}"
              ></type-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额" prop="amount">
              <el-popover ref="amount" placement="top-start" trigger="focus">
                <span style="color: teal;">{{form.amount|money}}</span>
              </el-popover>
              <el-input
                v-popover:amount
                type="number"
                v-model="form.amount"
                placeholder="金额"
                clearable
                @mousewheel.native.prevent
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="备注" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="info" @click="save(false)">保存关闭</el-button>
            <el-button type="success" @click="save(true)">保存新增</el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div>
      <add-type
        typeKind="ExpenseType"
        :item="expenseTypeItem"
        @success="id=>{this.form.expenseTypeId=id}"
      ></add-type>
    </div>
  </div>
</template>

<script>
import guid from "@/common/guid.js";
import setName from "@/common/setName";
import TypeCascader from "@/components/TypeCascader";
import AddType from "@/components/BaseType/TypeItem";
export default {
  props: ["item"],
  components: {
    TypeCascader,
    AddType
  },
  data() {
    return {
      visible: false,
      form: {},
      rules: {
        expenseTypeId: [{ required: true, message: "费用类别不能为空" }],
        amount: [
          {
            validator: (rule, value, callback) => {
              if (Number(value) == 0) {
                return callback(new Error("金额不能为0"));
              }
              callback();
            },
            trigger: "blur"
          }
        ]
      },
      expenseTypeItem: {}
    };
  },
  watch: {
    item: {
      handler: function(row) {
        if (row.id) {
          this.form = { ...row };
        } else {
          this.initForm();
        }
        this.visible = true;
        this.$nextTick(() => {
          this.$refs["form"].clearValidate();
        });
      },
      deep: true
    }
  },
  methods: {
    initForm() {
      this.form = {
        id: guid(),
        expenseTypeId: "",
        expenseTypeName: "",
        amount: null,
        remark: ""
      };
    },
    save(isContinue) {
      this.$refs["form"].validate(valid => {
        if (!valid) {
          return;
        }
        setName("ExpenseType", this.form, "expenseTypeId", "expenseTypeName");
        this.$emit("success", this.form);

        if (isContinue) {
          this.initForm();
        } else {
          this.visible = false;
        }
      });
    }
  }
};
</script>

<style>
</style>