<template>
  <div>
    <div class="bill-title">项目费用</div>
    <el-form
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="80px"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="单据编号" prop="billCode">
            <el-input v-model="form.billCode" placeholder="单据编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              style="width: 150px"
              @change="getBillCode(9)"
              v-model="form.billDate"
              placeholder="单据日期"
              :clearable="false"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="冲抵借款" prop="against">
            <el-radio-group v-model="form.against" @change="againstChang">
              <el-radio :label="true" border>冲抵</el-radio>
              <el-radio :label="false" border>不冲抵</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="项目" prop="projectId">
            <project-select
              v-model="form.projectId"
              :canAdd="true"
              :state="[1, 2]"
              :addId="addProjectId"
              @addClick="
                () => {
                  this.projectItem = {};
                }
              "
            ></project-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="员工" prop="employeeId">
            <base-select
              info="Employee"
              v-model="form.employeeId"
              :canAdd="true"
              @addClick="
                () => {
                  this.employeeItem = {};
                }
              "
            ></base-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="供应商" prop="supplierId" v-if="!form.against">
            <base-select
              info="Supplier"
              v-model="form.supplierId"
              :canAdd="true"
              @addClick="
                () => {
                  this.supplierItem = {};
                }
              "
              @input="supplierChange"
            ></base-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-show="form.supplierId">
        <el-col :span="10">
          <el-form-item label="银行账号">
            <el-input
              v-model="form.bankNo"
              placeholder="银行账号"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-checkbox v-model="builderPay">保存后生成付款单</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="摘要">
        <el-input v-model="form.remark" placeholder="摘要" clearable></el-input>
      </el-form-item>
      <el-form-item label="单据附件">
        <BillAttach :attachId="form.attachId"></BillAttach>
      </el-form-item>
      <el-form-item>
        <template slot="label">
          <strong>费用明细</strong>
        </template>
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="detail = {}"
        ></el-button>
      </el-form-item>
      <div style="margin: -8px 0px 8px 80px">
        <el-table :data="detailList" @row-dblclick="editDetail" stripe border>
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column
            label="费用类别"
            prop="expenseTypeName"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="金额" prop="amount">
            <template slot-scope="scope">{{
              scope.row.amount | thousands
            }}</template>
          </el-table-column>
          <el-table-column
            label="备注"
            prop="remark"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="操作" width="65">
            <template slot-scope="scope">
              <el-tag
                v-if="!scope.row.isStop"
                type="danger"
                effect="dark"
                @click="deleteDetail(scope.row)"
                >移除</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-form-item>
        <span v-if="canntEditMsg" style="color: red"
          >{{ canntEditMsg }} 单据不可编辑</span
        >
        <el-button-group v-else>
          <el-button type="info" @click="save(false)">保存关闭</el-button>
          <el-button type="success" @click="save(true)">保存新增</el-button>
          <el-button type="warning" @click="cancel">取消关闭</el-button>
          <el-button type="danger" @click="deleteBill" v-if="form.id&&hasPermission('DeleteBill')"
            >删除单据</el-button
          >
        </el-button-group>

        <span style="margin-left: 10px">
          金额合计
          <el-tag type="warning">{{ totalAmount | thousands }}</el-tag
          >元
        </span>
      </el-form-item>
      <el-form-item>
        <operator-box :item="form"></operator-box>
      </el-form-item>
    </el-form>
    <div>
      <PaymentList :billId="form.id"></PaymentList>
    </div>
    <div>
      <add-project
        :item="projectItem"
        @success="
          (id) => {
            this.form.projectId = id;
          }
        "
      ></add-project>
      <add-employee
        :item="employeeItem"
        @success="
          (id) => {
            this.form.employeeId = id;
          }
        "
      ></add-employee>
      <expense-detail :item="detail" @success="updateDetail"></expense-detail>
      <add-supplier
        :item="supplierItem"
        @success="
          (id) => {
            this.form.supplierId = id;
          }
        "
      ></add-supplier>
    </div>
  </div>
</template>

<script>
import setName from "@/common/setName";
import billcode from "@/common/billcodeMixins.js";
import BaseSelect from "@/components/BaseSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";

import AddProject from "@/views/project/Item.vue";
import AddEmployee from "@/views/employee/Item";
import AddSupplier from "@/views/supplier/Item";

import ExpenseDetail from "@/views/expense/Detail.vue";

import addPay from "@/common/addPay.js";
import PaymentList from "@/components/PaymentList";
import OperatorBox from "@/components/OperatorBox";
import guid from "@/common/guid.js";
import BillAttach from "@/components/BillAttach";
export default {
  mixins: [billcode],
  components: {
    BaseSelect,
    ProjectSelect,
    AddProject,
    AddEmployee,
    AddSupplier,
    ExpenseDetail,
    PaymentList,
    OperatorBox,
    BillAttach,
  },
  data() {
    return {
      routeTab: "项目费用",
      form: {},
      rules: {
        billCode: [{ required: true, message: "编号不能为空" }],
        billDate: [{ required: true, message: "日期不能为空" }],
        against: [{ required: true, message: "是否冲抵不能为空" }],
        projectId: [{ required: true, message: "项目不能为空" }],
        employeeId: [
          {
            validator: (rule, value, callback) => {
              if (this.form.against && !value) {
                return callback(new Error("冲抵借款时，员工不能为空"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },

      detailList: [],
      detail: {},

      addProjectId: null, //不可用项目Id(编辑时要显示)
      stopList: [], //停用基础信息
      canntEditMsg: "", //不可编辑的提示信息

      projectItem: {},
      employeeItem: {},
      supplierItem: {},

      builderPay: false,
      isContinue: false,
    };
  },
  computed: {
    totalAmount() {
      let total = 0;
      this.detailList.forEach((item) => {
        total += Number(item.amount);
      });
      return total;
    },
  },
  mounted() {
    this.init(this.$route.query.id);
  },
  beforeDestroy() {
    this.stopList.forEach((item) => {
      this.$store.commit("removeBaseInfo", {
        info: item.info,
        id: item.baseInfo.id,
      });
    });
  },
  methods: {
    //初始化
    init(id) {
      this.addProjectId = null;
      this.stopList = [];
      this.canntEditMsg = "";

      this.builderPay = false;
      if (id) {
        this.editInit(id);
      } else {
        this.addInit();
      }
    },
    //新增初始化
    addInit() {
      this.form = {
        billCode: "",
        billDate: new Date(),
        against: true,
        projectId: "",
        employeeId: "",
        remark: "",
        supplierId: "",
        bankNo: "",
        attachId: guid(),
      };
      this.detailList = [];

      this.getBillCode(9);
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    //编辑初始化
    editInit(id) {
      this.$get("Expense/Detail", {
        id,
      }).then((r) => {
        this.routeTab = r.bill.billCode;
        this.form = { ...r.bill };
        let detailList = [...r.detail];
        detailList.forEach((item) => {
          setName("ExpenseType", item, "expenseTypeId", "expenseTypeName");
        });

        this.detailList = detailList;

        this.addProjectId = r.addProjectId;
        this.stopList = r.stopList;
        this.canntEditMsg = r.canntEditMsg;
        this.stopList.forEach((item) => {
          this.$store.commit("addBaseInfo", {
            info: item.info,
            item: item.baseInfo,
          });
        });
      });
    },
    againstChang(against) {
      this.form.supplierId = "";
      this.builderPay = false;
      this.supplierChange();
    },
    supplierChange(supplierId) {
      if (supplierId) {
        this.$get("Supplier/GetSupplierInfo", {
          supplierId: supplierId,
        }).then((r) => {
          this.form.bankNo = r.bankNo;
        });
      } else {
        this.form.bankNo = "";
      }
    },
    //双击明细，编辑
    editDetail(row) {
      this.detail = { ...row };
    },
    //明细确定
    updateDetail(row) {
      let index = -1;
      this.detailList.forEach((item, i) => {
        if (item.id == row.id) {
          index = i;
        }
      });
      if (index == -1) {
        //添加
        this.detailList.push(row);
      } else {
        //修改
        this.detailList.splice(index, 1, row);
      }
    },
    // 移除明细
    deleteDetail(row) {
      let index = this.detailList.findIndex((d) => {
        return d.id == row.id;
      });
      this.detailList.splice(index, 1);
    },
    //保存
    save(isContinue) {
      this.isContinue = isContinue;
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.detailList.length == 0) {
          this.$message.error("请添加费用明细");
          return;
        }

        let bill = { ...this.form };
        let detail = [...this.detailList];
        let loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        if (this.form.id) {
          this.$post("Expense/Edit", {
            bill,
            detail,
          })
            .then((r) => {
              if (this.builderPay && bill.supplierId) {
                addPay(
                  bill.billDate,
                  bill.supplierId,
                  bill.bankNo,
                  2,
                  r,
                  this.totalAmount,
                  this.saveCallBack
                );
              } else {
                this.saveCallBack();
              }
            })
            .finally(() => {
              loading.close();
            });
        } else {
          this.$post("Expense/Add", {
            bill,
            detail,
          })
            .then((r) => {
              if (this.builderPay && bill.supplierId) {
                addPay(
                  bill.billDate,
                  bill.supplierId,
                  bill.bankNo,
                  2,
                  r,
                  this.totalAmount,
                  this.saveCallBack
                );
              } else {
                this.saveCallBack();
              }
            })
            .finally(() => {
              loading.close();
            });
        }
      });
    },
    saveCallBack() {
      this.$message.success("操作成功！");
      if (this.isContinue) {
        this.$tabs.close({
          to: {
            name: "ExpenseItem",
            query: {},
          },
          refresh: true,
        });
      } else {
        this.$tabs.close();
      }
    },
    //删除单据
    deleteBill() {
      this.$confirm("将删除此费用单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$post("Expense/Delete?id=" + this.form.id)
            .then((r) => {
              this.$message.success("操作成功！");
              this.$g.deleteAttach(this.form.attachId);
              this.$tabs.close();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    cancel() {
      if (!this.form.id) {
        this.$g.deleteAttach(this.form.attachId);
      }
      this.$tabs.close();
    },
  },
};
</script>

<style>
</style>